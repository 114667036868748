import { Country, Offer, Organization, WithContext } from "schema-dts";

import { ES_BASE_URL } from "~/Constant";

export const EQUIPMENTSHARE_LOGO_URL =
  "https://static.estrack.com/files/logos/equipmentshare-horizontal.svg";

export const EQUIPMENTSHARE_ICON_URL =
  "https://uploads-ssl.webflow.com/60cb2013a506c737cfeddf74/60d0867d1398775f9a3669b2_logo-256x256.png";

export const ORGANIZATION_SCHEMA: WithContext<Exclude<Organization, string>> = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "EquipmentShare",
  sameAs: ES_BASE_URL,
  logo: EQUIPMENTSHARE_LOGO_URL,
};

export const COUNTRY_SERVED: WithContext<Country> = {
  "@context": "https://schema.org",
  "@type": "Country",
  name: "US",
};

export const RENTAL_DAILY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "OfferForLease",
  availability: "InStock",
  name: "Daily Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 1,
    maxValue: 6,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
};

export const RENTAL_WEEKLY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "OfferForLease",
  availability: "InStock",
  name: "Weekly Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 7,
    maxValue: 27,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
};

export const RENTAL_MONTHLY_OFFER: WithContext<Offer> = {
  "@context": "https://schema.org",
  "@type": "OfferForLease",
  availability: "InStock",
  name: "Monthly Rental",
  areaServed: COUNTRY_SERVED,
  leaseLength: {
    "@type": "QuantitativeValue",
    minValue: 28,
    unitCode: "DAY",
  },
  offeredBy: ORGANIZATION_SCHEMA,
};
