/* eslint-disable jest/require-hook */
import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

import { ModalLauncherMountProps, ModalLauncherProps } from "./types";

import { analyticsEvent } from "~/Analytics";
import { APPLET_MANIFEST } from "~/AppletManifest";
import { AppletWrapper } from "~/Atom/AppletWrapper";
import { isProduction } from "~/env";
import { id } from "~/utils";

const Applet: React.FC<ModalLauncherProps> = ({ appletName, forwardProps }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const { mount, mountId, useModalWidth } = APPLET_MANIFEST[appletName];

  const modalWidth = useModalWidth();

  const onModalClose = () => {
    const event = new CustomEvent('omnichannel-modal-closed');
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (mountId) {
      if (isModalOpen) {
        const el = document.getElementById(mountId);

        if (el?.tagName === "DIV") {
          mount(el as HTMLDivElement);
        }
      } else if (isProduction) {
        // self destruct on production--stay alive in staging/dev
        document.getElementById(id(mountId))?.remove();
      }
    }
  }, [isModalOpen, appletName]);

  useEffect(() => {
    analyticsEvent(
      isModalOpen ? "applet_modallauncher_open" : "applet_modallauncher_close",
      { applet_name: appletName }
    );
  }, [isModalOpen, appletName]);

  return (
    <AppletWrapper appletName={appletName}>
      {!isProduction && !isModalOpen && (
        <Button
          type="primary"
          onClick={() => setIsModalOpen(true)}
          style={{
            display: "block",
            margin: "auto",
          }}
        >
          {"Reopen Modal"}
        </Button>
      )}

      {/* Do not mount modal unless it's open--prevents applet mount useEffects from firing */}
      {isModalOpen && (
        <Modal
          open={true}
          centered={true}
          onCancel={() => {
            setIsModalOpen(false);
            onModalClose();
          }}
          onOk={() => {
            setIsModalOpen(false)
            onModalClose();
          }}
          width={modalWidth}
          destroyOnClose={true}
          maskClosable={false}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <div {...forwardProps} id={mountId} />
        </Modal>
      )}
    </AppletWrapper>
  );
};

export const mount = ({ appletName, el }: ModalLauncherMountProps) => {
  const root = createRoot(el);

  root.render(
    <Applet
      appletName={appletName}
      forwardProps={Object.fromEntries(
        el
          .getAttributeNames()
          .map((attributeName) => [
            attributeName,
            el.getAttribute(attributeName),
          ])
      )}
    />
  );
};
